<template>
  <div class="join">
    <div class="baseBox">
      <div class="joinBox">
        <router-link to="/" class="logo">
          <img src="@/assets/images/logo.png" alt="logo" />
        </router-link>
        <h2>Create account</h2>
        <div class="form">
          <div class="flex">
            <p>
              Name
              <span>*</span>
            </p>
            <div class="name">
              <input type="text" v-model="firstName" placeholder="First name" />
              <input type="text" v-model="lastName" placeholder="Last name" />
            </div>
          </div>
          <div class="flex">
            <p>
              Verify email
              <span>*</span>
            </p>
            <div class="cert">
              <input type="text" v-model="email" :disabled="isVerify == true" />
              <button class="point" @click="requestCertCode">get code</button>
            </div>
          </div>
          <div class="flex" v-show="isRequestCertCode">
            <p>
              Vefication code
              <span>*</span>
            </p>
            <div class="cert">
              <input
                class="tel"
                type="text"
                v-model="certCode"
                placeholder="Please enter the verification code."
              />
              <button class="pointOut" @click="verifyCode"></button>
            </div>
          </div>
          <div class="flex">
            <p>
              Password
              <span>*</span>
            </p>
            <div class="password">
              <input type="password" v-model="password" />
            </div>
            <!-- <span v-if="error == 'checkPassword'" class="error right">8~12자이내, 영문+숫자+특수문자</span> -->
          </div>
          <div class="flex">
            <p>
              Repeat Password
              <span>*</span>
            </p>
            <div class="password">
              <input type="password" v-model="passwordConfirm" />
            </div>
          </div>

          <!-- <div class="flex">
            <p>
              Verify email
              <span>*</span>
            </p>
            <div class="cert">
              <input type="text" v-model="mobileNo" @keyup="removeChar" />
              <button class="point" @click="requestCertNumber">
                인증번호 받기
              </button>
            </div>
          </div> -->
          <!-- <div class="flex" v-show="isRequestCertNum">
            <p>
              인증번호
              <span>*</span>
            </p>
            <div class="cert">
              <input
                class="tel"
                type="tel"
                v-model="certNumber"
                @keyup="removeChar"
                placeholder="인증번호를 입력해 주세요."
              />
              <button class="pointOut" @click="verifyNumber">
                인증번호 확인
              </button>
            </div>
          </div> -->

          <!-- <div class="termsAgree">
            <input type="checkbox" v-model="personDataCollectAgreeFlag" />
            I accept
            <span @click="handleTerms('UseTerms')">Terms</span> &amp;
            <span @click="handleTerms('TermsPrivacy')">Conditions</span>.
          </div> -->
        </div>
        <button class="point large" @click="submit">Sign up</button>
      </div>
      <!-- <el-drawer
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose"
        size="100%"
      >
        <component v-bind:is="currentComponent"></component>
      </el-drawer> -->
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
// import moment from "moment";
import { createUser, verifyEmail, createCertCode } from "@/api/index";
//import UseTerms from "@/views/terms/UseTerms.vue";
//import TermsPrivacy from "@/views/terms/TermsPrivacy.vue";
// import region from "@/config/region.json";
export default {
  mixins: [format],
  // components: { UseTerms, TermsPrivacy },

  data() {
    return {
      // moment: moment,
      email: "",
      // mobileNo: "",
      firstName: "",
      lastName: "",
      // userName:"",
      password: "",
      passwordConfirm: "",
      isRequestCertCode: false,
      // personDataCollectAgreeFlag: false,
      // drawer: false,
      // direction: "btt",
      // currentComponent: null,
      certCode: "",
      isVerify: false,
      verifiedEmail: "",
    };
  },
  mounted() {
    // this.setYear();
    // this.region = region.region;
    // this.getAccountList();
  },
  methods: {
    // setYear() {
    //   let today = new Date();
    //   this.year = today.getFullYear();
    // },
    // handleClose() {
    //   this.drawer = false;
    //   var container = this.$el.querySelector(".el-drawer__body");
    //   container.scrollTop = 0;
    // },
    // handleTerms(component) {
    //   this.drawer = true;
    //   this.currentComponent = component;
    // },
    // getAccountList() {
    //   fetchAccountList().then((res) => {
    //     this.accountList = res.data.data;
    //   });
    // },

    // removeChar(event) {
    //   if (
    //     event.keyCode == 8 ||
    //     event.keyCode == 46 ||
    //     event.keyCode == 37 ||
    //     event.keyCode == 39
    //   )
    //     return;
    //   else event.target.value = event.target.value.replace(/[^0-9]/g, "");
    // },
    requestCertCode() {
      if (this.email == "") {
        return alert("Enter your email address.");
      } else if (this.emailValidate(this.email) == false) {
        return alert("Please enter your valid and entire email address");
      }
      let data = {
        email: this.email,
      };
      createCertCode(data).then((res) => {
        if (res.data.status == 200) {
          this.isRequestCertCode = true;
          return alert(
            "Please enter the verification code that was sent to the email address registered for your account."
          );
        } else {
          alert(res.data.message);
        }
      });
    },
    verifyCode() {
      if (this.certCode == "") {
        return alert("Please enter the verification code.");
      }
      let data = {
        email: this.email,
        certCode: this.certCode,
      };
      verifyEmail(data).then((res) => {
        if (res.data.status == 200) {
          this.isVerify = true;
          this.isRequestCertCode = false;
          this.verifiedEmail = this.email;
          return alert("Verification Successful.");
        } else {
          this.isVerify = false;
          return alert(res.data.message);
        }
      });
    },
    submit() {
      if (this.firstName == "") {
        return alert("Enter your first name.");
      } else if (this.lastName == "") {
        return alert("Enter your last name.");
      } else if (this.isVerify == false) {
        return alert("Please verify your email address.");
      } else if (this.email != this.verifiedEmail) {
        return alert("Email address is not verified.");
      } else if (this.password == "") {
        return alert("Enter password");
      } else if (this.checkPassword(this.password) == false) {
        return;
      } else if (this.password != this.passwordConfirm) {
        return alert("Password you entered is invalid.");
      }
      // if (this.isVerify == false) {
      //   return alert("인증번호를 확인해 주세요");
      // } else if (this.personDataCollectAgreeFlag == false) {
      //   return alert("이용약관 및 개인정보 처리방침는 필수 입니다.");
      // }
      // this.birth = this.birthYear + "-" + this.month + "-" + this.day;

      let data = {
        email: this.email,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        // userTerms: this.personDataCollectAgreeFlag,
        // privacyPolicy: this.personDataCollectAgreeFlag,
      };
      createUser(data).then((res) => {
        if (res.data.status == 200) {
          this.$router.push("/joinDone");
        } else if (res.data.status == 409) {
          let message = res.data.message;
          alert(message);
        } else {
          console.log("createUser", res.data.message);
        }
      });
    },
  },
};
</script>
